<template>
  <div class="detailSesi">
    <a-modal
      v-model="openModalAssignTopic"
      title="Assign Topic"
      @cancel="toggleModalManageSesi"
      centered
    >
      <form-topik
        :params="{ id_level: idLevel, id_mata_pelajaran: idMapel }"
        :listSelected="[session]"
        :data="newData"
        @handle-change="handleChange"
      />
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManageSesi">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModalManageSesi"
          @click="handleOkModalManageSesi"
        >
          Assign Now
        </a-button>
      </template>
    </a-modal>
    <a-modal
      centered
      :visible="modalVisible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black',
      }"
      okText="Update"
      :okButtonProps="{
        props: {
          shape: 'round',
        },
      }"
      :cancelButtonProps="{
        props: {
          shape: 'round',
        },
      }"
      id="modal-update-confirmation"
    >
      <h3 slot="title" style="color: #faad14">
        <a-icon style="font-size: 1.2em" type="warning" />UPDATE SESSION
      </h3>
      <p>
        Are you sure to update your session? Your data before will be lost once
        you update.
      </p>
    </a-modal>
    <div id="coverDetail">
      <!-- Sebelum lempar props : {{currentSlot.id}} -->
      <h2>
        {{kelas}} |
        {{ mapel }}
      </h2>
      <div>
        Session {{ week }} / 24 |
        {{ session.topik && session.topik.nama ? topik(session.topik.nama) : "-" }}
        | {{ sessionDate }}, {{ sessionTime }}
      </div>
    </div>
    <div
      v-if="isInSubjectHours && liveClass.zoom_setting"
      class="countdownTImer"
    >
      <h4>SESSION TIME LEFT</h4>
      <h2>{{ formattedDuration }}</h2>
    </div>
    <div id="contentDetail">
      <a-row type="flex" class="menuSchedule">
        <a-col :span="!isDesktop ? 24 : !isEdit ? 21 : 20">
          <a-menu v-model="current" mode="horizontal">
            <a-menu-item id="live-class-teacher" key="live class" class="subMenuDetail">
              <a-icon
                type="video-camera"
                style="color: #ff0000; font-size: 22px"
                v-if="isLive && isNow"
              />
              {{ isLive && isNow ? "LIVE CLASS" : "DETAIL CLASS" }}
            </a-menu-item>
            <a-menu-item id="materials-teacher" key="materials" class="subMenuDetail"
              >MATERIALS</a-menu-item
            >
            <a-menu-item id="assignments-teacher" key="assignments" class="subMenuDetail"
              >ASSIGNMENTS</a-menu-item
            >
          </a-menu>
        </a-col>
        <!-- <a-col :span="1" v-if="isEdit"></a-col> -->
        <a-col
          :span="!isDesktop ? 24 : !isEdit ? 3 : 4"
          :class="[!isDesktop ? 'mt-5' : '']"
          :style="{
            display: 'flex',
            'justify-content': !isDesktop ? 'center' : 'flex-end',
            'align-self': !isDesktop ? 'center' : 'flex-end',
          }"
        >
          <a-button
            v-if="!isEdit"
            @click.prevent="startEdit"
            shape="round"
            type="primary"
            icon="edit"
            id="edit-session"
            >EDIT SESSION</a-button
          >
          <div
            v-else
            style="margin-left: 7%; display: flex; justify-content: flex-end"
          >
            <a-button
              @click.prevent="cancelEdit"
              style="margin-right: 5%"
              shape="round"
              :loading="cancelEditLoading"
              icon="edit"
              type="danger"
              >CANCEL</a-button
            >
            <form
              @submit.prevent="updateDetailSesi"
              enctype="multipart/form-data"
              style="display: inline-block"
            >
              <a-button
                htmlType="submit"
                shape="round"
                icon="save"
                style="background-color: #41b883; color: white"
                id="save-session"
                >SAVE</a-button
              >
            </form>
          </div>
        </a-col>
      </a-row>
      <div>
        <!-- <transition-group name="slide-fadein-up"> -->
        <LiveClass
          key="1"
          v-if="liveClass"
          v-show="current[0] === 'live class' && !isEdit"
          :liveClass="liveClass"
          :attendances="attendances"
          :isStudent="false"
          :isNow="isNow"
          :model="model"
          :session="session"
          :attendanceAuthorized="attendanceAuthorized"
        />
        <LiveClassEdit
          key="2"
          :liveClass="liveClass"
          v-show="liveClass && current[0] === 'live class' && isEdit"
          @updatedLive="cekLive"
          :isEdit="isEdit"
          :model="model"
          :session="session"
        />
        <a-empty :image-style="{ height: '100px' }" v-show="!materials.id && current[0] === 'materials'">
          <div slot="description">
            <span class="font-size-18" style="color: #0000009C">No Topic / Materials</span>
            <br />
            <span style="color: #7474747a">You have not assigned any topic on this session.</span>
            <template v-if="!isEdit">
              <br />
              <span style="color: #7474747a">You can either <span @click.prevent="createNewTopic" class="text-primary">create</span> new topic or <span class="text-primary" @click.prevent="toggleModalManageSesi">assign</span> existing topic</span>
            </template>
          </div>
        </a-empty>
        <Materials
          key="3"
          v-if="materials.id && !isFetching"
          v-show="current[0] === 'materials' && !isEdit"
          :materials="materials"
          :videoUrls="videoUrls"
          :fileMaterials="fileMaterials"
        />
        <MaterialsEdit
          key="4"
          :session="currentSlot"
          :materials="materials"
          :videoUrls="videoUrls"
          :fileMaterials="fileMaterials"
          v-show="materials.id && current[0] === 'materials' && isEdit"
          @updatedMaterial="cekMaterial"
          :isEdit="isEdit"
        />
        <Assignments
          key="5"
          v-if="session.slot && !isFetching"
          v-show="current[0] === 'assignments' && !isEdit"
          :session="session"
          :isTeacher="true"
          :taskType="session.tipe_assignment"
        />
        <AssignmentsEdit
          key="6"
          :session="session"
          :attendances="attendances"
          :isAttendanceSubmitted="isAttendanceSubmitted"
          v-show="session.slot && current[0] === 'assignments' && isEdit"
          :isTeacher="true"
          @updatedAssignment="cekAssignment"
          :isEdit="isEdit"
          :daftarKelas="daftarKelas"
          :sessionTime="sessionTime"
        />
        <!-- </transition-group> -->
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
// import axios from 'axios'
import LiveClass from '@/components/app/DetailSchedule/LiveClass'
import Materials from '@/components/app/DetailSchedule/Materials'
import Assignments from '@/components/app/DetailSchedule/Assignments'
import AssignmentsEdit from '@/components/app/DetailSchedule/EditSession/Assignments'
import MaterialsEdit from '@/components/app/DetailSchedule/EditSession/Materials'
import LiveClassEdit from '@/components/app/DetailSchedule/EditSession/Live'
const formTopik = () => import('@/views/Teacher/Planning/New/Session/Form/Topik')

// import $ from 'jquery'
// import { ZoomMtg } from '@zoomus/websdk'

export default {
  name: 'DetailScheduleTeacher',
  components: {
    LiveClass,
    Materials,
    Assignments,
    AssignmentsEdit,
    MaterialsEdit,
    LiveClassEdit,
    formTopik,
  },
  data() {
    return {
      idWeek: null,
      isEdit: false,
      isClicked: false,
      liveClass: {},
      sesiProperty: {},
      topikProperty: {},
      attendances: [],
      modalVisible: false,
      confirmLoading: false,
      time: moment(),
      nextTime: null,
      duration: 0,
      formattedDuration: '00:00:00',
      cancelEditLoading: false,
      daftarKelas: [],
      idSession: this.$route.params.id,
      session: {},
      model: {
        current: {},
        next: {},
      },
      idNextSession: null,
      attendanceAuthorized: true,
      loadingActionModalManageSesi: false,
      openModalAssignTopic: false,
      newData: {
        id: null,
      },
      isAttendanceSubmitted: false,
      isFetching: false,
    }
  },
  mounted() {
    // this.makeDuration()
  },
  methods: {
    async handleOk(e) {
      try {
        const idSession = this.$route.params.id
        Object.assign(this.sesiProperty, {
          id: idSession,
        })
        Object.assign(this.topikProperty, {
          id: this.materials.id,
        })

        if (this.sesiProperty.tipe_assignment === '') {
          this.sesiProperty.tipe_assignment = null
        }
        if (this.sesiProperty.tugas_deadline2 === 'Invalid date') {
          this.sesiProperty.tugas_deadline2 = null
        }
        // console.log('SESI', this.sesiProperty)
        // console.log('TOPIK', this.topikProperty)
        this.confirmLoading = true
        await this.$store.dispatch('slot/PUT_DETAIL_SESI', {
          data: [this.sesiProperty, this.topikProperty],
        })
        await this.fetchSession()
        this.modalVisible = false
        this.confirmLoading = false
        this.isEdit = false
        this.$notification.success({
          message: 'Successfully update session',
        })
        // console.log('OK')
        // const idSession = this.$route.params.id
        // e.preventDefault()
      } catch (err) {
        await this.fetchSession()
        this.modalVisible = false
        this.confirmLoading = false
        this.isEdit = false
        this.$notification.error({
          message: 'Sorry.',
          description: 'Session can\'t be updated right now. Try again later.',
        })
        console.log(err)
      }
    },
    cancelEdit() {
      this.fetchSession()
      this.cancelEditLoading = true
      setTimeout(() => {
        this.isEdit = false
        this.cancelEditLoading = false
      }, 2000)
    },
    handleCancel(e) {
      // e.preventDefault()
      this.modalVisible = false
    },
    makeDuration() {
      const currentTime = moment().locale('id')
      const diffTime = this.nextTime - currentTime
      this.duration = moment.duration(diffTime, 'milliseconds')
      // console.log(this.duration)
      const timerInterval = setInterval(() => {
        if (this.duration < 0) {
          clearInterval(timerInterval)
        } else {
          this.duration = moment.duration(this.duration - 1000, 'milliseconds')
          this.formattedDuration = `${this.duration.hours() >= 10 ? '' : '0'}${this.duration.hours()}:${this.duration.minutes() >= 10 ? '' : '0'}${this.duration.minutes()}:${this.duration.seconds() >= 10 ? '' : '0'}${this.duration.seconds()}`
        }
      }, 1000)
      return this.nextTime.diff(this.time, 'miliseconds')
    },
    findWeekSlot(idSlot) {
      const { slots } = this.$store.state.slot
      slots.map(slot => {
        if (slot.id === this.$route.params.id) {
          this.idWeek = slot.id
        }
      })
    },
    topik(text) {
      let string = ''
      if (text.length > 25) {
        string = text.substring(0, 50) + '...'
      } else {
        string = text
      }
      return string
    },
    cekLive(value) {
      this.sesiProperty.zoom_setting = value
    },
    cekMaterial(value) {
      this.topikProperty.nama = value.nama
      this.topikProperty.sub_topik = value.subTopik
      this.topikProperty.brief = value.brief
      this.topikProperty.video_url1 = value.videos[0] ? value.videos[0] : null
      this.topikProperty.video_url2 = value.videos[1] ? value.videos[1] : null
      this.topikProperty.video_url3 = value.videos[2] ? value.videos[2] : null
      this.topikProperty.video_url4 = value.videos[3] ? value.videos[3] : null
      this.topikProperty.video_url5 = value.videos[4] ? value.videos[4] : null
    },
    cekAssignment(value) {
      this.sesiProperty.tipe_assignment = value.tipe_assignment
      this.sesiProperty.tugas_deadline2 = value.tugas_deadline2 ? moment(value.tugas_deadline2, 'YYYY-MM-DD HH:mm').locale('id').format('YYYY-MM-DD HH:mm') : null
      this.sesiProperty.tugas_deskripsi = value.tugas_deskripsi
      this.sesiProperty.pgSoalBundle = value.questions
      this.sesiProperty.classList = value.classList
    },
    updateDetailSesi() {
      // console.log(moment(waktu, 'YYYY-MM-DD HH:mm'), moment(this.sesiProperty.tugas_deadline2, 'YYYY-MM-DD HH:mm'), this.currentSlot.tugas_deadline2)

      if (this.sesiProperty.tipe_assignment === 'Multiple Choice') {
        const isValidated = this.sesiProperty.pgSoalBundle.find(q => {
          if (!q.soal || !q.jawaban) return true
          const multipleChoiceAmount = this.institusi.jumlah_pilihan_ganda || 4
          for (let i = 0; i < multipleChoiceAmount; i++) {
            const huruf = String.fromCharCode(65 + i).toLowerCase()
            if (!q[`pilihan_${huruf}`]) return true
          }
          return false
        })
        if (isValidated) {
          return this.$notification.error({
            message: 'Error',
            description:
              "Saving failed because of question's assignment, make sure you have question and answer",
          })
        }
      }
      const deadlineData = moment(this.session.tugas_deadline2, 'YYYY-MM-DD HH:mm').add(7, 'hour')
      const deadlinePayload = moment(this.sesiProperty.tugas_deadline2, 'YYYY-MM-DD HH:mm')
      // console.log(deadlineData.locale('id').format('DD MMMM YYYY HH:mm'), deadlinePayload.locale('id').format('DD MMMM YYYY HH:mm'))
      const sessionEvent = moment(`${this.session.tanggal_sesi}, ${this.sessionTime}`, 'YYYY-MM-DD, HH:mm')
      const boundary = sessionEvent.isAfter(moment(), 'second') ? sessionEvent : moment()
      // console.log(!deadlineData.isSame(deadlinePayload), deadlineData, deadlinePayload)
      const deadlineRestriction = !deadlineData.isSame(deadlinePayload) && deadlinePayload.isBefore(boundary, 'seconds', '[)')
      if (this.sesiProperty.tipe_assignment && (!this.sesiProperty.tugas_deadline2 || this.sesiProperty.tugas_deadline2 === 'Invalid date')) {
        this.$notification.error({
          message: 'Sorry.',
          description: 'Please fill due date if you assign a task.',
        })
      } else if (deadlineRestriction) {
        this.$notification.error({
          message: 'Sorry.',
          description: 'Please fill due date more than now.',
        })
      } else {
        this.modalVisible = true
      }
    },
    async fetchSession() {
      try {
        this.isFetching = true
        const { session, idNextSession, model } = await this.$store.dispatch('slot/FETCH_CURRENT_SLOT', { idSession: this.idSession })
        this.session = {
          ...session,
          key: session.id,
        }
        this.idNextSession = idNextSession
        this.model = model
        this.isFetching = false
        return new Promise((resolve, reject) => resolve(session))
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    startEdit() {
      this.isEdit = true
    },
    createNewTopic() {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to create new topic?</div>
        ),
        onOk: async () => {
          try {
            const idLevel = this.session.slot.kela.id_level
            const idMapel = this.session.slot.id_mata_pelajaran
            const topik = await this.$store.dispatch('master/CREATE_TOPIC', { nama: 'New Topic', subtopik: 'New Topic', id_level: idLevel, id_mata_pelajaran: idMapel })
            await this.$store.dispatch('sesi/POST_SESI_TO_TOPIK', { dataId: { id: topik.id }, dataSesi: [{ id: this.idSession }] })
            await this.fetchSession()
            this.$notification.success({
              message: 'Successfully add topic',
            })
          } catch (err) {
            await this.fetchSession()
            this.$notification.error({
              message: 'Sorry.',
              description: 'Cannot add topic on this session',
            })
            console.log(err)
          }
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Yes, Create now',
        cancelText: 'Cancel',
      })
    },
    async toggleModalManageSesi() {
      try {
        this.openModalAssignTopic = !this.openModalAssignTopic
      } catch (err) {
        console.log(err)
      }
    },
    async handleOkModalManageSesi() {
      try {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure to assign topic on this session?</div>
          ),
          onOk: async () => {
            try {
              this.loadingActionModalManageSesi = true
              await this.$store.dispatch('sesi/POST_SESI_TO_TOPIK', { dataId: this.newData, dataSesi: [{ id: this.idSession }] })
              await this.fetchSession()
              this.$notification.success({
                message: 'Successfully assign topic',
              })
              this.toggleModalManageSesi()
              this.loadingActionModalManageSesi = false
            } catch (err) {
              await this.fetchSession()
              this.$notification.error({
                message: 'Sorry.',
                description: 'Cannot assign topic on this session',
              })
              console.log(err)
            }
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Yes, Assign now',
          cancelText: 'Cancel',
        })
      } catch (err) {
        console.log(err)
      }
    },
    handleChange(payload) {
      const { value, column } = payload
      // console.log('parent', payload)
      this.newData[column] = value
    },
  },
  async created() {
    try {
      this.$store.commit('menu/SET_STATE', {
        currentDetailScheduleMenu: ['live class'],
      })
      await this.fetchSession()
      const { zoom_setting: zoomSetting } = this.session
      this.time = moment(`${this.session.tanggal_sesi} ${this.model.current.jam_mulai}`, 'YYYY-MM-DD HH:mm')
      this.nextTime = this.model.next.jam_mulai ? moment(`${this.session.tanggal_sesi} ${this.model.next.jam_mulai}`, 'YYYY-MM-DD HH:mm', 'HH:mm') : null
      this.sesiProperty = {
        id: this.session.id,
        zoom_setting: this.session.zoom_setting,
        tipe_assignment: this.session.tipe_assignment,
        tugas_deadline2: this.session.tugas_deadline2,
        tugas_deskripsi: this.session.tugas_deskripsi,
        pgSoalBundle: this.session.pg_soal,
        classList: [{ id_kelas: this.session.slot.kela.id }],
      }
      if (this.session.topik?.id) {
        this.topikProperty = {
          id: this.session.topik.id,
          nama: this.session.topik.nama,
          sub_topik: this.session.topik.subtopik,
          brief: this.session.topik.brief,
          video_url1: this.session.topik.video_url1,
          video_url2: this.session.topik.video_url2,
          video_url3: this.session.topik.video_url3,
          video_url4: this.session.topik.video_url4,
          video_url5: this.session.topik.video_url5,
        }
        await this.$store.dispatch('slot/FETCH_FILE_MATERIAL', { idTopik: this.session.topik.id })
      }
      this.liveClass = {
        zoom_setting: this.session.zoom_setting,
        video_conference: this.institusi.video_conference,
        // student: this.session.slot.kela?.kelas_tahun_ajaran?.murids,
      }
      // ZOOM CONFIG
      if (zoomSetting && this.isNow && this.nextTime) {
        this.$store.commit('SET_STATE', {
          isLoading: true,
        }, { root: true })
        this.makeDuration()
      }
      const kelas = await this.$store.dispatch('slot/FETCH_CLASS_ASSIGNMENT_BY_MAPEL_TEACHER', {
        idMapel: this.session.slot.mata_pelajaran?.id,
        idWeek: this.session.id_week,
        idLevel: this.session.slot?.kela?.id_level,
      })
      this.daftarKelas = kelas.sort((a, b) => {
        return a < b
      })
      const { attendances, attendanceAuthorized, isAttendanceSubmitted } = await this.$store.dispatch('live/FETCH_ALL_MURID_FOR_ABSENSI', {
        idSession: this.idSession,
        idMapel: this.session.slot.mata_pelajaran.id,
        idKelas: this.session.slot.kela.id,
        idModel: this.model.current.id,
      })

      this.isAttendanceSubmitted = isAttendanceSubmitted

      if (attendanceAuthorized) this.attendances = attendances
      else this.attendanceAuthorized = attendanceAuthorized
      if (this.liveClass.zoom_setting && this.liveClass.video_conference === 'zoom' && this.isNow) {
        const { kela: kelas } = this.session.slot
        if (kelas.zoom_url && kelas.zoom_password) {
          const config = {
            confno: kelas.zoom_url,
            pwd: kelas.zoom_password,
            token: kelas.zoom_token,
            zc: 0,
          }
          const protocol = this.isMobile ? 'zoomus://' : 'zoommtg://'
          let link = config.token ? `${protocol}zoom.us/start?` : `${protocol}zoom.us/join?`

          for (const key in config) {
            link += `${key}=${config[key]}&`
          }
          const a = document.createElement('a')
          a.href = link
          a.click()
        } else {
          this.$notification.error({
            message: "CAN'T START VIDEO CONFERENCE ROOM",
            description: 'This class has no zoom data for entering the room. Please contact admin for further information.',
          })
        }
      }
    } catch (err) {
      console.log(err)
    }
    // this.findWeekSlot(idSlot)
  },
  computed: {
    isInSubjectHours() {
      return moment().isBetween(this.time, this.nextTime, undefined, '[]')
    },
    currentSlot() { return this.$store.state.slot.currentSlot },
    sessionDate() { return moment(this.session.tanggal_sesi, 'YYYY-MM-DD').locale('id').format('DD MMMM YYYY') },
    sessionTime() {
      return moment(this.time, 'HH:mm').locale('id').format('HH:mm')
    },
    materials() { return this.session.topik || {} },
    isLive() { return this.session.zoom_setting },
    user() { return this.$store.state.user.user },
    isDone() { return moment(`${this.sessionDate} ${this.sessionTime}`, 'DD MMMM YYYY HH:mm').isBefore(moment(), 'days') },
    isNow() {
      // return true
      // console.log(moment().isBetween(time, nextTime, undefined, '[]'))
      // console.log(moment().days())
      // return moment().days() === this.time.days()
      // console.log(this.time.format('dddd') === 'Tuesday', moment().isSame(this.time, 'days'))
      // return moment().isSame(this.time, 'days') && moment().isBefore(moment('13:00', 'HH:mm')) && moment().isAfter(moment('06:00', 'HH:mm'))
      return moment().isSame(this.time, 'days')
    },
    videoUrls() {
      const data = []
      for (let i = 1; i <= 5; i++) {
        if (this.materials[`video_url${i}`] && this.materials[`video_url${i}`] !== '') {
          data.push(this.materials[`video_url${i}`])
        }
      }
      return data
    },
    fileMaterials() {
      return this.$store.state.slot.fileMaterials
    },
    kelas() {
      if (this.session.slot?.kela) {
        return `${this.session.slot.kela.level.nama} - ${this.session.slot.kela.simbol}`
      }
      return '-'
    },
    mapel() {
      return this.session.slot?.mata_pelajaran.nama
    },
    week() {
      return this.session.week?.week
    },

    current: {
      get: function() {
        return this.$store.state.menu.currentDetailScheduleMenu
      },
      set: function(value) {
        this.$store.commit('menu/SET_STATE', {
          currentDetailScheduleMenu: value,
        })
      },
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    idLevel() {
      return this.session?.slot?.kela?.id_level
    },
    idMapel() {
      return this.session?.slot?.id_mata_pelajaran
    },
    institusi() {
      return this.$store.state.master.institusi
    },
  },
}
</script>
<style lang="scss">
.detailSesi {
  overflow-x: hidden;
  span.text-primary {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  #coverDetail {
    width: 100%;
    min-height: 145px;
    background-color: var(--kit-color-primary);
    border-radius: 10px;
    padding: 40px;
    color: #ffffff;
    /* opacity: 0.8;
    z-index: 10; */
  }

  .countdownTImer {
    width: 211px;
    height: 120px;
    background-color: white;
    position: fixed;
    bottom: 10%;
    right: 5%;
    z-index: 99999;
    box-shadow: 0px 5px 15px #6d807b80;
    display: flex;
    flex-direction: column;
    padding: 30px;
    h4 {
      color: #041930;
    }
    h2 {
      margin: 0;
      color: #ff0000;
    }
  }

  h2 {
    color: #ffffff;
  }

  .menuSchedule {
    margin-bottom: 35px;
    .ant-col-20 {
      padding-right: 25px;
    }

    .ant-col-21 {
      padding-right: 10px;
    }
  }

  #contentDetail {
    width: 100%;
    min-height: 20vh;
    background-color: white;
    padding: 40px;
    z-index: 11;
    margin-top: -10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #0419301a;
    margin-left: 1px;

    @media (max-width: 425px) {
      padding: 20px;
    }
  }

  .subMenuDetail {
    font-family: "Mukta", "sans-serif";
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 1.5px;
    /* color: #041930; */
  }

  .form-submit {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    padding: 10px 45px;
    background: var(--kit-color-primary) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    border-style: none;
    color: #ffffff;
  }

  .form-submit:active {
    border-style: none;
  }
}
</style>
