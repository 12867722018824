var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-block d-lg-none"},[_c('a-form-item',[_c('a-select',{staticStyle:{"width":"100%","height":"40px"},attrs:{"size":"large","value":_vm.week},on:{"change":_vm.handleChangeWeek}},_vm._l((_vm.weeks),function(index){return _c('a-select-option',{key:index.week,attrs:{"value":index.week}},[_vm._v("Week "+_vm._s(index.week)+" "+_vm._s(index.week === _vm.currentWeek ? "(This week)" : ""))])}),1)],1),_c('a-form-item',[_c('a-select',{staticStyle:{"width":"100%","height":"40px"},attrs:{"size":"large","value":_vm.selectedDay},on:{"change":_vm.handleChangeDay}},_vm._l((_vm.daysMobile),function(day,index){return _c('a-select-option',{key:index,attrs:{"value":index}},[_vm._v(_vm._s(day)+", "+_vm._s(_vm.dayShowedSchedule("Mobile", index))+" "+_vm._s(_vm.dayShowedSchedule("Mobile", index) === _vm.todayDateStr ? "(Today)" : ""))])}),1)],1),_c('hr'),_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"timeScheduleMobile",style:(_vm.inlineStyleTimeSchedule)},[_vm._l((_vm.models),function(time,index){return [(time.type === 'istirahat')?_c('div',{key:index,staticStyle:{"display":"flex","align-items":"center"}},[_c('p',{staticStyle:{"text-align":"right","color":"#707788"}},[_vm._v(" BREAK ("+_vm._s(time.startAt)+") ")])]):_c('div',{key:index,staticClass:"scheduleAndTime time"},[_c('div',[_c('h3',[_vm._v(_vm._s(time.startAt))]),_c('div',{staticClass:"text-right font-size-14",staticStyle:{"color":"#707788"}},[_vm._v(_vm._s(time.endAt))])])])]})],2),_c('div',{staticClass:"scheduleMobile",style:(_vm.inlineStyleTimeSchedule)},[_vm._l((_vm.models),function(model,n){return [_c('div',{key:n,staticClass:"scheduleAndTime slotSchedule",style:({
              padding: model.type === 'istirahat' ? 0 : '',
              border: model.type === 'istirahat' ? 'none' : '',
            })},[(model.type === 'istirahat')?_c('div',{staticClass:"break"}):[_vm._l((_vm.dayToIndexSlots),function(slot,idxSlot){return [_vm._l((slot.sesis),function(session,idx){return [(
                      model.position === slot.posisi &&
                      session.week.week === _vm.week &&
                      slot.hari === _vm.selectedDay + 1
                    )?_c('div',{key:`${idxSlot} dan ${idx}`,staticClass:"boxSlot",on:{"click":function($event){$event.preventDefault();return _vm.toDetail(session.id)}}},[_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('a-col',{attrs:{"span":20}},[(_vm.role.includes('guruMapel'))?_c('h3',{style:({
                            color: _vm.colorTime(
                              slot.posisi,
                              _vm.week,
                              slot.hari
                            ).textColor,
                          })},[_vm._v(" "+_vm._s(slot.kela.tingkat)+"-"+_vm._s(slot.kela.simbol)+" ")]):(_vm.role.includes('murid'))?_c('div',{staticClass:"subH3",style:({
                            color: _vm.colorTime(
                              slot.posisi,
                              _vm.week,
                              slot.hari
                            ).textColor,
                          })},[_vm._v(" "+_vm._s(slot.mata_pelajaran.nama)+" ")]):_vm._e()]),_c('a-col',{attrs:{"span":4}},[(session.zoom_setting)?_c('a-icon',{style:({
                            color: _vm.colorTime(
                              slot.posisi,
                              _vm.week,
                              slot.hari
                            ).iconColor,
                            'font-size': '22px',
                          }),attrs:{"type":"video-camera"}}):[(
                              !_vm.colorTime(slot.posisi, _vm.week, slot.hari)
                                .status
                            )?_c('div',{staticClass:"circleUndone",style:({
                              border: `2px solid ${
                                _vm.colorTime(
                                  slot.posisi,
                                  _vm.week,
                                  slot.hari
                                ).iconColor
                              }`,
                            })}):_c('a-icon',{style:({
                              color: _vm.colorTime(
                                slot.posisi,
                                _vm.week,
                                slot.hari
                              ).iconColor,
                              'font-size': '22px',
                            }),attrs:{"type":"check-circle"}})]],2)],1),(_vm.role.includes('guruMapel'))?_c('div',{staticClass:"subH3"},[_vm._v(" "+_vm._s(slot.mata_pelajaran.nama)+" ")]):_vm._e()],1):_vm._e()]})]})]],2)]})],2)])],1),_c('div',{staticClass:"d-none d-lg-block"},[_c('div',{staticClass:"scheduleContainer"},[_c('div',{staticClass:"headerSchedule"},[_c('div',{staticClass:"nestedHeaderSchedule"},[_vm._l((_vm.datesSchedule),function(day,index){return [(day.day === '')?_c('div',{key:index}):[_c('div',{key:index,staticClass:"contentHeaderSchedule"},[_c('h3',[(
                      day.date === _vm.todayDateStr &&
                      _vm.isClass
                    )?_c('div',{staticClass:"circleToday mt-1 mr-1"}):_vm._e(),_vm._v(" "+_vm._s(day.day)+" ")]),(day.date === _vm.todayDateStr)?_c('div',{staticClass:"subH3-blue"},[_vm._v(" "+_vm._s(day.date)+" ")]):_c('div',{staticClass:"subH3"},[_vm._v(" "+_vm._s(day.date)+" ")])])]]})],2)]),_c('div',{staticClass:"timeSchedule",style:(_vm.inlineStyleTimeSchedule)},[_vm._l((_vm.models),function(time,idx){return [(time.type === 'istirahat')?_c('div',{key:idx,staticStyle:{"display":"flex","align-items":"center"}},[_c('p',{staticStyle:{"text-align":"right","color":"#707788"}},[_vm._v(" BREAK ("+_vm._s(time.startAt)+") ")])]):_c('div',{key:idx,staticClass:"scheduleAndTime time"},[_c('div',[_c('h3',[_vm._v(_vm._s(time.startAt))]),_c('div',{staticClass:"text-right font-size-14",staticStyle:{"color":"#707788"}},[_vm._v(_vm._s(time.endAt))])])])]})],2),_c('div',{staticClass:"schedules"},[_vm._l((_vm.colSchedule.desktop),function(n){return [_c('div',{key:n,staticClass:"scheduleAndTime slotSchedule",style:({
              padding: _vm.findPos(n).type === 'istirahat' ? 0 : '',
              border:
                _vm.findPos(n).type === 'istirahat' ? 'none' : '',
            })},[(_vm.findPos(n).type === 'istirahat')?_c('div',{staticClass:"break"}):[(_vm.slots.length > 0)?[_vm._l((_vm.dayToIndexSlots),function(slot,idxSlot){return [_vm._l((slot.sesis),function(session,idx){return [(
                        n % 5 === slot.hari &&
                        _vm.findPos(n).position === slot.posisi &&
                        session.week.week === _vm.week
                      )?_c('div',{key:`${idxSlot} dan ${idx}`,staticClass:"boxSlot",attrs:{"id":"box-slot"},on:{"click":function($event){$event.preventDefault();return _vm.toDetail(session.id)}}},[_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('a-col',{attrs:{"span":20}},[(_vm.role.includes('guruMapel'))?_c('h3',{style:({
                              color: _vm.colorTime(slot.posisi, _vm.week, slot.hari)
                                .textColor,
                            })},[_vm._v(" "+_vm._s(slot.kela.tingkat)+"-"+_vm._s(slot.kela.simbol)+" ")]):(_vm.role.includes('murid'))?_c('div',{staticClass:"subH3",style:({
                              color: _vm.colorTime(slot.posisi, _vm.week, slot.hari)
                                .textColor,
                            })},[_vm._v(" "+_vm._s(slot.mata_pelajaran.nama)+" ")]):_vm._e()]),_c('a-col',{attrs:{"span":4}},[(session.zoom_setting)?_c('a-icon',{style:({
                              color: _vm.colorTime(slot.posisi, _vm.week, slot.hari)
                                .iconColor,
                              'font-size': '22px',
                            }),attrs:{"type":"video-camera"}}):[(
                                !_vm.colorTime(slot.posisi, _vm.week, slot.hari)
                                  .status
                              )?_c('div',{staticClass:"circleUndone",style:({
                                border: `2px solid ${
                                  _vm.colorTime(slot.posisi, _vm.week, slot.hari)
                                    .iconColor
                                }`,
                              })}):_c('a-icon',{style:({
                                color: _vm.colorTime(slot.posisi, _vm.week, slot.hari)
                                  .iconColor,
                                'font-size': '22px',
                              }),attrs:{"type":"check-circle"}})]],2)],1),(_vm.role.includes('guruMapel'))?_c('div',{staticClass:"subH3"},[_vm._v(" "+_vm._s(slot.mata_pelajaran.nama)+" ")]):_vm._e()],1):_vm._e()]})]})]:_vm._e()]],2)]})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }