<template>
  <div>
    <!-- MOBILE -->
    <div class="d-block d-lg-none">
      <a-form-item>
        <a-select
          @change="handleChangeWeek"
          size="large"
          style="width: 100%; height: 40px"
          :value="week"
        >
          <a-select-option v-for="index in weeks" :key="index.week" :value="index.week"
            >Week {{ index.week }}
            {{ index.week === currentWeek ? "(This week)" : "" }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-select
          @change="handleChangeDay"
          size="large"
          style="width: 100%; height: 40px"
          :value="selectedDay"
        >
          <a-select-option
            v-for="(day, index) in daysMobile"
            :key="index"
            :value="index"
            >{{ day }}, {{ dayShowedSchedule("Mobile", index) }}
            {{
              dayShowedSchedule("Mobile", index) === todayDateStr ? "(Today)" : ""
            }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <hr />
      <div class="d-flex flex-row">
        <div class="timeScheduleMobile" :style="inlineStyleTimeSchedule">
          <template v-for="(time, index) in models">
            <div
              style="display: flex; align-items: center"
              :key="index"
              v-if="time.type === 'istirahat'"
            >
              <p style="text-align: right; color: #707788">
                BREAK ({{ time.startAt }})
              </p>
            </div>
            <div v-else class="scheduleAndTime time" :key="index">
              <div>
                <h3>{{ time.startAt }}</h3>
                <div style="color: #707788;" class="text-right font-size-14">{{ time.endAt }}</div>
              </div>
            </div>
          </template>
        </div>
        <div class="scheduleMobile" :style="inlineStyleTimeSchedule">
          <template v-for="(model, n) in models">
            <div
              :key="n"
              :style="{
                padding: model.type === 'istirahat' ? 0 : '',
                border: model.type === 'istirahat' ? 'none' : '',
              }"
              class="scheduleAndTime slotSchedule"
            >
              <div class="break" v-if="model.type === 'istirahat'"></div>
              <template v-else>
                <!-- <template v-for="(box, i) in boxes">
                  <div
                    @click.prevent="toDetail(box.sesiId)"
                    :key="i"
                    class="boxSlot"
                    v-if="box.status && box.posisi === n"
                  >
                    <div class="d-flex align-items-center justify-content-between">
                      <div v-if="role.includes('guruMapel')">
                        <h3
                          :style="{
                              color: colorTime(box.posisi, selectedWeek, box.hari).textColor
                              }"
                        >{{box.kelas}}</h3>
                        <div
                          :style="{
                              color: colorTime(box.posisi, selectedWeek, box.hari).textColor
                            }"
                          class="subH3"
                        >{{box.mataPelajaran}}</div>
                      </div>
                      <div v-else-if="role.includes('murid')">
                        <div
                          :style="{
                              color: colorTime(box.posisi, selectedWeek, box.hari).textColor
                            }"
                          class="subH3"
                        >{{box.mataPelajaran}}</div>
                      </div>
                      <div v-if="box.zoomStatus">
                        <a-icon
                          type="video-camera"
                          :style="{
                              color: colorTime(box.posisi, selectedWeek, box.hari).iconColor,
                              'font-size': '22px'}"
                        />
                      </div>
                      <div v-else>
                        <div
                          v-if="!colorTime(box.posisi, selectedWeek, box.hari).status"
                          class="circleUndone"
                          :style="{
                                border: `2px solid ${colorTime(box.posisi, selectedWeek, box.hari).iconColor}`}"
                        />
                        <a-icon
                          v-else
                          type="check-circle"
                          :style="{
                              color: colorTime(box.posisi, selectedWeek, box.hari).iconColor,
                              'font-size': '22px'}"
                        />
                      </div>
                    </div>
                  </div>
                </template>-->
                <template v-for="(slot, idxSlot) in dayToIndexSlots">
                  <template v-for="(session, idx) in slot.sesis">
                    <div
                      v-if="
                        model.position === slot.posisi &&
                        session.week.week === week &&
                        slot.hari === selectedDay + 1
                      "
                      @click.prevent="toDetail(session.id)"
                      class="boxSlot"
                      :key="`${idxSlot} dan ${idx}`"
                    >
                      <a-row type="flex" align="middle">
                        <a-col :span="20">
                          <h3
                            :style="{
                              color: colorTime(
                                slot.posisi,
                                week,
                                slot.hari
                              ).textColor,
                            }"
                            v-if="role.includes('guruMapel')"
                          >
                            {{ slot.kela.tingkat }}-{{ slot.kela.simbol }}
                          </h3>
                          <div
                            :style="{
                              color: colorTime(
                                slot.posisi,
                                week,
                                slot.hari
                              ).textColor,
                            }"
                            v-else-if="role.includes('murid')"
                            class="subH3"
                          >
                            {{ slot.mata_pelajaran.nama }}
                          </div>
                        </a-col>
                        <a-col :span="4">
                          <a-icon
                            v-if="session.zoom_setting"
                            type="video-camera"
                            :style="{
                              color: colorTime(
                                slot.posisi,
                                week,
                                slot.hari
                              ).iconColor,
                              'font-size': '22px',
                            }"
                          />
                          <template v-else>
                            <div
                              v-if="
                                !colorTime(slot.posisi, week, slot.hari)
                                  .status
                              "
                              class="circleUndone"
                              :style="{
                                border: `2px solid ${
                                  colorTime(
                                    slot.posisi,
                                    week,
                                    slot.hari
                                  ).iconColor
                                }`,
                              }"
                            />
                            <a-icon
                              v-else
                              type="check-circle"
                              :style="{
                                color: colorTime(
                                  slot.posisi,
                                  week,
                                  slot.hari
                                ).iconColor,
                                'font-size': '22px',
                              }"
                            />
                          </template>
                        </a-col>
                      </a-row>
                      <div v-if="role.includes('guruMapel')" class="subH3">
                        {{ slot.mata_pelajaran.nama }}
                      </div>
                    </div>
                  </template>
                </template>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- DESKTOP -->
    <div class="d-none d-lg-block">
      <div class="scheduleContainer">
        <div class="headerSchedule">
          <div class="nestedHeaderSchedule">
            <!-- Date and day -->
            <template v-for="(day, index) in datesSchedule">
              <div v-if="day.day === ''" :key="index" />
              <template v-else>
                <div :key="index" class="contentHeaderSchedule">
                  <h3>
                    <div
                      v-if="
                        day.date === todayDateStr &&
                        isClass
                      "
                      class="circleToday mt-1 mr-1"
                    />
                    {{ day.day }}
                  </h3>
                  <div
                    v-if="day.date === todayDateStr"
                    class="subH3-blue"
                  >
                    {{ day.date }}
                  </div>
                  <div v-else class="subH3">
                    {{ day.date }}
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
        <!-- time -->
        <div class="timeSchedule" :style="inlineStyleTimeSchedule">
          <template v-for="(time, idx) in models">
            <div
              style="display: flex; align-items: center"
              :key="idx"
              v-if="time.type === 'istirahat'"
            >
              <p style="text-align: right; color: #707788;">
                BREAK ({{ time.startAt }})
              </p>
            </div>
            <div v-else class="scheduleAndTime time" :key="idx">
              <div>
                <h3>{{ time.startAt }}</h3>
                <!-- <div style="color: #707788;" class="text-right font-size-14">{{ time.endAt }}</div> -->
                <div style="color: #707788;" class="text-right font-size-14">{{ time.endAt }}</div>
              </div>
            </div>
          </template>
        </div>
        <div class="schedules">
          <template v-for="n in colSchedule.desktop">
            <div
              :style="{
                padding: findPos(n).type === 'istirahat' ? 0 : '',
                border:
                  findPos(n).type === 'istirahat' ? 'none' : '',
              }"
              :key="n"
              class="scheduleAndTime slotSchedule"
            >
              <div
                class="break"
                v-if="findPos(n).type === 'istirahat'"
              >
                <!-- break -->
              </div>
              <template v-else>
                <template v-if="slots.length > 0">
                  <template v-for="(slot, idxSlot) in dayToIndexSlots">
                    <template v-for="(session, idx) in slot.sesis">
                      <div
                        v-if="
                          n % 5 === slot.hari &&
                          findPos(n).position === slot.posisi &&
                          session.week.week === week
                        "
                        @click.prevent="toDetail(session.id)"
                        class="boxSlot"
                        id="box-slot"
                        :key="`${idxSlot} dan ${idx}`"
                      >
                        <a-row type="flex" align="middle">
                          <a-col :span="20">
                            <h3
                              :style="{
                                color: colorTime(slot.posisi, week, slot.hari)
                                  .textColor,
                              }"
                              v-if="role.includes('guruMapel')"
                            >
                              {{ slot.kela.tingkat }}-{{ slot.kela.simbol }}
                            </h3>
                            <div
                              :style="{
                                color: colorTime(slot.posisi, week, slot.hari)
                                  .textColor,
                              }"
                              v-else-if="role.includes('murid')"
                              class="subH3"
                            >
                              {{ slot.mata_pelajaran.nama }}
                            </div>
                          </a-col>
                          <a-col :span="4">
                            <a-icon
                              v-if="session.zoom_setting"
                              type="video-camera"
                              :style="{
                                color: colorTime(slot.posisi, week, slot.hari)
                                  .iconColor,
                                'font-size': '22px',
                              }"
                            />
                            <template v-else>
                              <div
                                v-if="
                                  !colorTime(slot.posisi, week, slot.hari)
                                    .status
                                "
                                class="circleUndone"
                                :style="{
                                  border: `2px solid ${
                                    colorTime(slot.posisi, week, slot.hari)
                                      .iconColor
                                  }`,
                                }"
                              />
                              <a-icon
                                v-else
                                type="check-circle"
                                :style="{
                                  color: colorTime(slot.posisi, week, slot.hari)
                                    .iconColor,
                                  'font-size': '22px',
                                }"
                              />
                            </template>
                          </a-col>
                        </a-row>
                        <div v-if="role.includes('guruMapel')" class="subH3">
                          {{ slot.mata_pelajaran.nama }}
                        </div>
                      </div>
                    </template>
                  </template>
                </template>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'BoxSchedule',
  data() {
    return {
      days: ['', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat'],
      daysMobile: ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu'],
      currentPosition: null,
      hariIniNumDiffWithMonday: moment().diff(moment().startOf('isoWeek'), 'days') + 1,
      times: [],
      boxes: [],
      isClass: false,
      selectedWeek: '',
      selectedDay: '',
      selectedDayString: '',
    }
  },
  props: ['semesterFirstDay', 'slots', 'week', 'currentWeek', 'models', 'weekData', 'weeks', 'tahunAjaran'],
  methods: {
    findPos(n) {
      const position = Math.ceil(n / 5)
      if (position) {
        const data = this.models.find(model => model.position === position)
        // console.log(data)
        return {
          ...data,
        }
      }

      return {}
    },
    handleChangeWeek(value) {
      this.selectedWeek = value
      this.handleChangeDay(this.selectedDay)
      this.$emit('changeWeek', value)
      // this.findBoxes()
    },
    handleChangeDay(value) {
      this.selectedDay = value
      const day = this.dayShowedScheduleFormat('Mobile', value)
      this.selectedDayString = day
      // this.findBoxes()
    },
    colorTime(posisi, week, hari) {
      const hariNum = hari === 0 ? 5 : hari
      if (posisi === this.currentPos && week === this.currentWeek && hariNum === this.hariIniNumDiffWithMonday) {
        this.isClass = true
        return {
          textColor: '#FF0000',
          iconColor: '#FF0000',
          status: false,
        }
      } else {
        if (this.currentWeek) {
          if (week < this.currentWeek) {
            return {
              textColor: '#041930',
              iconColor: '#707788',
              status: true,
            }
          } else if (week > this.currentWeek) {
            return {
              textColor: '#041930',
              iconColor: '#1B55E3',
              status: false,
            }
          } else {
            // check if slot's day less or more than today with integer perspective, by finding the difference between monday and today's day
            if (hariNum < (moment().diff(moment().startOf('isoWeek'), 'days')) + 1) {
              // console.log(hariNum)
              return {
                textColor: '#041930',
                iconColor: '#707788',
                status: true,
              }
            } else if (hariNum > (moment().diff(moment().startOf('isoWeek'), 'days')) + 1) {
              return {
                textColor: '#041930',
                iconColor: '#1B55E3',
                status: false,
              }
            } else {
              if (this.currentPos > posisi) {
                return {
                  textColor: '#041930',
                  iconColor: '#707788',
                  status: true,
                }
              } else {
                return {
                  textColor: '#041930',
                  iconColor: '#1B55E3',
                  status: false,
                }
              }
            }
          }
        } else {
          // know if currentWeek is before or after the range of tahun_ajaran
          const firstWeek = this.weeks.find(week => week.week === 1)
          const lastWeek = this.weeks.find(week => week.week === this.weeks.length)
          if (firstWeek && lastWeek) {
            const tanggalAwalTahunAjaran = moment(firstWeek.tanggal_awal, 'YYYY-MM-DD')
            const tanggalAkhirTahunAjaran = moment(lastWeek.tanggal_akhir, 'YYYY-MM-DD')
            if (moment().isBefore(tanggalAwalTahunAjaran, 'days')) {
              return {
                textColor: '#041930',
                iconColor: '#1B55E3',
                status: false,
              }
            } else if (moment().isAfter(tanggalAkhirTahunAjaran, 'days')) {
              return {
                textColor: '#041930',
                iconColor: '#707788',
                status: true,
              }
            } else {
              return {
                textColor: '#041930',
                iconColor: '#707788',
                status: true,
              }
            }
          } else {
            return {
              textColor: '#041930',
              iconColor: '#707788',
              status: true,
            }
          }
        }
      }
    },
    toDetail(id) {
      // console.log(id)
      if (this.role.includes('guruMapel')) {
        this.$router.push({ name: 'Detail Schedule Teacher', params: { id } })
        this.$store.commit('menu/SET_STATE', {
          currentDetailScheduleMenu: ['live class'],
        })
      } else if (this.role.includes('murid')) {
        this.$router.push({ name: 'Detail Schedule Student', params: { id } })
        this.$store.commit('menu/SET_STATE', {
          currentDetailScheduleMenu: ['live class'],
        })
      }
    },
    dayShowedSchedule(type = '', day) {
      return moment(this.mondayShowedSchedule).add(day, 'days').locale('id').format('DD MMMM YYYY')
    },
    dayShowedScheduleFormat(type = '', day) {
      return moment(this.mondayShowedSchedule).add(day, 'days').locale('id').format('YYYY-MM-DD')
    },
    // findBoxes() {
    //   const sesis = this.slots.map(elSlot => {
    //     let sesi
    //     let sesiId
    //     let status = false
    //     let zoomStatus = false
    //     elSlot.sesis.forEach(elSesi => {
    //       zoomStatus = elSesi.zoom_setting
    //       if (elSesi.week.week === this.selectedWeek) {
    //         if (elSesi.tanggal_sesi === this.selectedDayString) {
    //           sesi = elSesi.tanggal_sesi
    //           status = true
    //           sesiId = elSesi.id
    //         }
    //       }
    //     })
    //     return {
    //       mataPelajaran: elSlot.mata_pelajaran.nama,
    //       kelas: `${elSlot.kela.tingkat}-${elSlot.kela.simbol}`,
    //       posisi: elSlot.posisi,
    //       hari: elSlot.hari,
    //       status,
    //       sesi,
    //       sesiId,
    //       zoomStatus,
    //     }
    //   })
    //   this.boxes = sesis
    // },
  },
  created() {
    // this.selectedWeek = moment().diff(this.semesterFirstDay, 'weeks') + 1
    const day = moment().isoWeekday() - 1
    this.handleChangeDay(day)
    // this.selectedDayString = this.dayShowedScheduleFormat('Mobile', this.selectedDay)
    // for (let i = 0; i < 10; i++) {
    //   if (i === 0) {
    //     const time = moment(this.modelJadwal.jam_mulai, 'HH:mm')
    //     const nextTime = moment(time, 'HH:mm').add(this.modelJadwal.durasi, 'minutes')
    //     // console.log(time, nextTime, i, 'all')
    //     if (moment().isBetween(time, nextTime, 'minutes', '[)')) {
    //       this.currentPosition = i + 1
    //     }
    //     this.times.push(this.modelJadwal.jam_mulai)
    //   } else {
    //     if (i === 4) {
    //       const time = moment(this.times[i - 1], 'HH:mm').add(this.modelJadwal.durasi, 'minutes')
    //       const nextTime = moment(time, 'HH:mm').add(15, 'minutes')
    //       // console.log(time, nextTime, i)
    //       if (moment().isBetween(time, nextTime, 'minutes', '[)')) {
    //         // console.log(i, this.currentPosition, time.format('HH:mm'), nextTime.format('HH:mm'), 'oyasaspue')
    //         this.currentPosition = i + 1
    //       }
    //       this.times.push(time.format('HH:mm'))
    //     } else {
    //       const time = moment(this.times[i - 1], 'HH:mm').add(i !== 5 ? this.modelJadwal.durasi : 15, 'minutes')
    //       const nextTime = moment(time, 'HH:mm').add(this.modelJadwal.durasi, 'minutes')
    //       // console.log(time, nextTime, i)
    //       if (moment().isBetween(time, nextTime, 'minutes', '[)')) {
    //         this.currentPosition = i + 1
    //         // console.log(i, this.currentPosition, time.format('HH:mm'), nextTime.format('HH:mm'), 'oyepue')
    //       }
    //       this.times.push(time.format('HH:mm'))
    //     }
    //   }
    // }

    // if (moment() < moment(this.times[0], 'HH:mm').locale('id')) {
    //   this.currentPosition = -1
    // } else if (moment() > moment(this.times[this.times.length - 1], 'HH:mm').locale('id')) {
    //   this.currentPosition = 100
    // }

    // console.log(this.slots)
    // this.findBoxes()
  },
  computed: {
    datesSchedule() {
      let days = [
        { day: '' },
        { day: 'Senin' },
        { day: 'Selasa' },
        { day: 'Rabu' },
        { day: 'Kamis' },
        { day: 'Jumat' },
      ]
      // let days = ['', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat']
      if (this.weekData) {
        const { tanggal_awal: tanggalAwal } = this.weekData
        const chosenWeekMonday = moment(tanggalAwal, 'YYYY-MM-DD').startOf('isoweek').format('DD-MM-YYYY')
        days = days.map((day, i) => {
          if (day.day) {
            const data = moment(chosenWeekMonday, 'DD-MM-YYYY').add(i - 1, 'day').locale('id')
            const payload = {
              day: data.format('dddd'),
              date: data.format('DD MMMM YYYY'),
            }
            return {
              ...day,
              ...payload,
              data,
            }
          }

          return day
        })
      }
      return days
    },
    dayToIndexSlots() {
      let newSlot = []
      if (this.slots?.length > 0) {
        newSlot = this.slots.map(slot => {
          switch (slot.hari) {
            case 'Senin':
              slot.hari = 1
              break
            case 'Selasa':
              slot.hari = 2
              break
            case 'Rabu':
              slot.hari = 3
              break
            case 'Kamis':
              slot.hari = 4
              break
            case 'Jumat':
              slot.hari = 0
              break
            default:
              slot.hari = -1
              break
          }
          return slot
        })
      }
      return newSlot
    },
    // semesterFirstDay() {
    //   return this.$store.state.slot.semesterFirstDay
    // },
    todayDateStr() {
      return moment().locale('id').format('DD MMMM YYYY')
    },
    role() {
      return this.$store.state.user.role
    },
    colSchedule() {
      return {
        mobile: this.models.length,
        desktop: this.models.length * 5, // depend on amount of days
      }
    },
    inlineStyleTimeSchedule() {
      const style = {
        'grid-template-rows': '',
      }

      this.models.forEach(model => {
        style['grid-template-rows'] += '1fr '
      })
      return style
    },
    currentPos() {
      let pos = 0
      this.models.forEach((model, i) => {
        const startAt = moment(model.startAt, 'HH:mm:ss')
        const endAt = moment(model.endAt, 'HH:mm:ss')
        const isBetween = moment().isBetween(startAt, endAt, 'minutes')
        if (isBetween) pos = model.position
        else {
          if (i === this.models.length - 1 && moment().isAfter(endAt, 'minutes')) pos = 99
        }
      })
      return pos
    },
    mondayShowedSchedule() {
      if (this.weekData) {
        return moment(this.weekData.tanggal_awal, 'YYYY-MM-DD').startOf('isoweek')
      }

      return moment()
    },
  },
}
</script>
<style scoped>
.subH3 {
  font-family: "Mukta", "sans-serif";
  font-weight: 600;
  font-size: 14px;
  color: #041930;
}

.subH3-blue {
  font-family: "Mukta", "sans-serif";
  font-weight: 600;
  font-size: 14px;
  color: #1b55e3;
}

.scheduleContainer {
  display: grid;
  grid-template-columns: 60px;
  /* grid-template-rows: 10vh repeat(5, 20vh); */
  /* grid-template-areas: "header header"
  "sidebar main"; */
  justify-items: center;
  align-items: center;
  /* padding: 20px; */
}

.circleUndone {
  width: 1.45em;
  height: 1.45em;
  border: 2px solid rgb(27, 85, 227);
  border-radius: 100%;
}

.circleToday {
  width: 0.7rem;
  height: 0.7rem;
  background-color: #ff0000;
  border-radius: 100%;
  float: left;
}

.headerSchedule {
  /* grid-area: header; */
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  width: 100%;
  /* grid-column: ; */
}

.break {
  width: 100%;
  height: 100%;
  background-color: #d3d9e6;
  color: white;
  /* border-radius: 10px; */
  /* margin: -2.5px; */
  /* padding: 10px 16px; */
  /* box-shadow: 0px 0px 2px #00000029; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nestedHeaderSchedule {
  display: grid;
  grid-template-columns: 5% repeat(5, 19%);
  /* justify-items: center; */
  align-items: center;
  padding-bottom: 10px;
}

.contentHeaderSchedule {
  padding-left: 20px;
}

.timeSchedule {
  display: grid;
  /* grid-template-rows: repeat(10, 1fr); */
  width: 100%;
  justify-items: end;
  padding-right: 15px;
}

.timeScheduleMobile {
  display: grid;
  width: 20%;
  justify-items: end;
  padding-right: 15px;
}

.schedules {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  width: 100%;
  height: 100%;
  justify-items: center;
  background-color: #ebf0f9;
}

.scheduleMobile {
  display: grid;
  /* grid-template-rows: repeat(10, 1fr); */
  width: 100%;
  height: 100%;
  justify-items: center;
  background-color: #ebf0f9;
}

.scheduleAndTime {
  min-height: 86.5px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.boxSlot {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px 16px;
  cursor: pointer;
  box-shadow: 0px 0px 2px #00000029;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.5s;
}

.boxSlot:hover {
  transform: translate(0, -5px);
  box-shadow: 0px 0px 20px #0419301a;
  transition: all 0.5s;
}

.contentBoxSlot {
  display: flex;
}

.slotSchedule {
  padding: 2.5px;
  border: 1px solid #d3d9e6;
}

.time {
  padding-top: 5px;
}
</style>
