<template>
  <div :class="$style.schedule">
    <a-row>
      <a-col :xs="24" :sm="24" :md="24" :xl="12">
        <h1>Schedule for {{ panggilan }} {{ user.nama }}</h1>
      </a-col>
      <a-col
        :xs="24"
        :sm="24"
        :md="12"
        :xl="12"
        style="text-align: right"
        class="d-none d-lg-block"
      >
        Week
        <a-select
          style="width: 22%"
          @change="handleChangeWeek"
          :value="selectedWeek"
        >
          <template v-for="n in weeks">
            <a-select-option v-if="n.week === currentWeek" :key="n.week" :value="n.week"
              >{{ n.week }} (this week)</a-select-option
            >
            <a-select-option :key="n.week" :value="n.week" v-else>{{
              n.week
            }}</a-select-option>
            <!-- <a-select-option :key="n" :value="n">{{ n }}</a-select-option> -->
          </template>
        </a-select>
      </a-col>
    </a-row>
    <LoadingState v-if="isLoading" />
    <div :id="$style.boxSchedule" v-else>
      <BoxSchedule
        :semesterFirstDay="semesterFirstDay"
        :slots="slots"
        :week="selectedWeek"
        :currentWeek="currentWeek"
        :models="models"
        :weekData="week"
        :weeks="weeks"
        @fetchSchedule="fetchSlot"
        @changeWeek="handleChangeWeek"
      />
    </div>
  </div>
</template>
<script>
import BoxSchedule from '@/components/app/BoxSchedule'
// import moment from 'moment'
import LoadingState from '@/components/app/LoadingState'
import moment from 'moment'

export default {
  components: {
    BoxSchedule,
    LoadingState,
  },
  data() {
    return {
      showClass: false,
      dataKelas: [],
      selectedWeek: null,
      currentWeek: null,
      semesterFirstDay: '',
      weeks: [],
      slots: [],
      models: [],
    }
  },
  methods: {
    handleChangeWeek(value) {
      this.selectedWeek = value
      this.fetchSlot()
    },
    async fetchModelActiveSchedule() {
      try {
        const data = await this.$store.dispatch('slot/FETCH_MODEL_ACTIVE_SCHEDULE')
        const modelJadwal = data.model_jadwals.sort((a, b) => a - b)
        this.models = modelJadwal.map((model, i) => {
          return {
            id: model.id,
            startAt: moment(model.jam_mulai, 'HH:mm:ss').format('HH:mm'),
            endAt: moment(model.jam_selesai, 'HH:mm:ss').format('HH:mm'),
            type: model.tipe_jam,
            position: i + 1,
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    async fetchWeek() {
      try {
        const { data, current } = await this.$store.dispatch('slot/FETCH_WEEKS')
        this.selectedWeek = current?.week || 1
        this.currentWeek = current.week
        this.weeks = data
        return new Promise((resolve) => resolve(true))
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchSlot() {
      try {
        // this.slots = await this.$store.dispatch('slot/FETCH_SLOT_TEACHER')
        this.slots = await this.$store.dispatch('slot/FETCH_SLOT_TEACHER', { idWeek: this.selectedWeek })
      } catch (err) {
        console.log(err)
      }
    },
  },
  async created() {
    this.fetchModelActiveSchedule()
    // this.$store.dispatch('slot/FETCH_TAHUN_AJARAN_ACTIVE')
    //   .then(res => {
    //     // console.log('res', res)
    //     this.semesterFirstDay = res
    //   })
    await this.fetchWeek()
    this.fetchSlot()
    // this.selectedWeek = moment().diff(this.semesterFirstDay, 'weeks') + 1
    // this.currentWeek = moment().diff(this.semesterFirstDay, 'weeks') + 1
  },
  computed: {
    allSlots () {
      return this.$store.state.slot.slots
    },
    user() {
      return this.$store.state.user.user
    },
    panggilan() {
      return this.$store.state.user.panggilan
    },
    isLoading() {
      return this.$store.state.isLoading
    },
    week() {
      // console.log(this.selectedWeek, 'tes')
      return this.weeks.find(week => week.week === this.selectedWeek)
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
