<template>
  <div :class="$style.liveClass">
    <a-divider orientation="left">
      <h3>Session Info</h3>
    </a-divider>
    <a-row>
      <a-col :xs="12" :span="6">
        <div style="margin-bottom: 2%!important;">
          <b>Week No.</b>
        </div>
        <p>
          <span style="color: #1B55E3;">
            <b>{{week}}</b>
          </span> /24
        </p>
      </a-col>
      <a-col :xs="12" :span="6">
        <div style="margin-bottom: 2%!important;">
          <b>Duration</b>
        </div>
        <p>{{durasi}}</p>
      </a-col>
      <a-col :xs="12" :span="6" class="mt-4 mt-lg-0">
        <!-- <a-row>
          <a-col :span="12"> -->
            <div style="margin-bottom: 2%!important;">
              <b>Live</b>
            </div>
            <div>
              <a-switch
                style="margin-bottom: 2%; background-color: #707788"
                :default-checked="live"
                :checked="live"
                @change="onChange"
                :disabled="isLiveRightNow"
              />
              <span style="color: #41B883" :class="$style.liveTag" v-if="live">
                <a-icon
                  type="video-camera"
                  style="font-size:20px;"
                  theme="twoTone"
                  two-tone-color="#52c41a"
                />&nbsp;Live
              </span>
              <span style="color: #707788" :class="$style.liveTag" v-else>Non-Live Class</span>
            </div>

            <!-- <p v-if="session.zoom_setting" style="color: #52c41a">
                <a-icon
                  type="video-camera"
                  style="font-size:20px;"
                  theme="twoTone"
                  two-tone-color="#52c41a"
                />&nbsp;Live
              </p>
            <p v-if="!session.zoom_setting">Non-Live Class</p>-->
          <!-- </a-col> -->
          <!-- <a-col :span="12"> -->
          <!-- </a-col>
        </a-row> -->
      </a-col>
      <a-col :xs="12" :span="6" class="mt-4 mt-lg-0">
        <div style="margin-bottom: 2%!important;">
              <b>Updated</b>
            </div>
            <p>{{updated}}</p>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  components: {
  },
  methods: {
    onChange(checked) {
      this.live = checked
      // console.log(`a-switch to ${checked}`)
      this.$emit('updatedLive', this.live)
    },
  },
  props: ['liveClass', 'isEdit', 'model', 'session'],
  data() {
    return {
      allIsAttend: false,
      live: false,
    }
  },
  computed: {
    updated() { return moment(this.session.updatedAt).locale('id').format('DD MMMM YYYY, HH:mm') },
    isLiveRightNow() { return this.$store.state.live.isLive },
    durasi() {
      if (this.session.durasi !== null && this.session.durasi !== undefined) {
        const hours = Math.floor(this.session.durasi / 60)
        const minutes = Math.floor(this.session.durasi % 60)
        console.log()
        return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`
      }
      return '00:00'
    },
    week() { return this.session.week?.week || '-' },
  },
  created() {
    this.live = this.session.zoom_setting
  },
  watch: {
    isEdit(newVal, oldVal) {
      this.live = this.session.zoom_setting
      this.$emit('updatedLive', this.live)
      // console.log('oye', this.live)
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
