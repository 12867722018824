<template>
  <div class="switchClass">
    <a-switch
      :default-checked="idKelasSession === kelas.id"
      :checked="isAssigned"
      @change="changeChecked"
      :disabled="isThisSession"
    />
    <span>{{this.tanggal}}</span>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  props: ['kelas', 'slotClass', 'idKelasSession'],
  computed: {
    sesi() {
      return this.slotClass.sesis[0]
    },
    tanggal() {
      return moment(this.slotClass.sesis[0].tanggal_sesi).format('DD MMMM YYYY')
    },
    isThisSession() {
      return this.slotClass.sesis[0].id === Number(this.$route.params.id)
    },
  },
  data() {
    return {
      isAssigned: false,
    }
  },
  methods: {
    changeChecked(value) {
      if (this.sesi.tipe_assignment && value) {
        return this.$confirm({
          title: 'Change Assignment',
          content: (
            <div>
              <div><strong>This session already has task</strong>. Are you sure to overwrite new assignment to { this.kelas?.level.nama }-{ this.kelas?.simbol } on {this.tanggal}?</div>
              <small style="color: red;">Once assigned and save, your last task can't be re-assigned.</small>
            </div>
          ),
          onOk: () => {
            this.isAssigned = value
            this.$emit('changeChecked', {
              isAssigned: value,
              kelas: this.kelas,
              id_sesi: this.slotClass.sesis[0].id,
            })
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Continue',
        })
      }
      this.isAssigned = value
      this.$emit('changeChecked', {
        isAssigned: value,
        kelas: this.kelas,
        id_sesi: this.slotClass.sesis[0].id,
      })
    },
  },
  mounted() {
    // console.log(this.slotClass.sesis[0].id, this.$route.params.id)
    if (this.isThisSession) {
      this.isAssigned = true
    }
  },
}
</script>
<style lang="scss">
  .switchClass {
    .ant-switch {
      margin-right: 5px;
    }
  }
</style>
