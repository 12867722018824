<template>
  <div class="assignmentStudent">
    <!-- sesudah lempar props edit assignment {{session.tipe_assignment}} -->
    <!-- {{assignment.classList}} -->
    <a-divider orientation="left">
      <h3>Assignment Info</h3>
    </a-divider>
    <a-row :gutter="40">
      <a-col :xs="24" :lg="12">
        <a-form-item label="Type">
          <a-select
            id="select-edit-tipe-assignment"
            v-model="assignment.tipe_assignment"
            :default-value="
              assignment.tipe_assignment ? assignment.tipe_assignment : ''
            "
            style="width: 100%"
            @change="onChange"
            placeholder="Select Assignment Type"
            :disabled="isAttendanceSubmitted"
          >
            <a-select-option value>
              <a-icon type="minus-circle" class="mr-2" />No Assignment
            </a-select-option>
            <a-select-option value="File Upload">
              <a-icon type="paper-clip" class="mr-2" />File Upload
            </a-select-option>
            <a-select-option value="Essay">
              <a-icon type="align-left" class="mr-2" />Essay
            </a-select-option>
            <a-select-option value="Multiple Choice">
              <a-icon type="unordered-list" class="mr-2" />Multiple Choice
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :lg="12">
        <a-form-item label="Due Date">
          <a-date-picker
            id="select-edit-due-date-assignment"
            :disabled-date="disabledStartDate"
            :show-time="{ defaultValue: defaultTime }"
            v-model="assignment.tugas_deadline2"
            v-decorator="['date-time-picker', configDate]"
            format="YYYY-MM-DD HH:mm:ss"
            :disabled="assignment.tipe_assignment === ''"
            placeholder="Select due date"
            style="width: 100%; height: auto"
            @change="onChange"
            :showToday="false"
          />
          <!-- <small style="color: red; position: relative;" v-if="assignment.tipe_assignment !== ''">*Required</small> -->
        </a-form-item>
      </a-col>
    </a-row>
    <div>
      <a-row :gutter="40">
        <a-col :xs="24"
        :md="24"
        :xl="24">
          <div class="mb-2">
            <label>Classes to Assign:</label>
          </div>
          <a-row type="flex" justify="space-between" :gutter="40">
            <template v-for="kelas in daftarKelas">
              <a-col
                class="mb-2"
                style="display: flex; flex-direction: column;"
                :xs="24"
                :md="12"
                :xl="6"
                :key="kelas.id"
              >
                <h5>{{ kelas.level.nama }}-{{ kelas.simbol }}</h5>
                <template v-for="(slot, i) in kelas.slots">
                  <SwitchClass
                    class="mb-3"
                    :key="i"
                    :idKelasSession="session.slot.id_kelas"
                    :slotClass="slot"
                    :kelas="kelas"
                    @changeChecked="changeChecked"
                  />
                </template>
              </a-col>
            </template>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <template v-if="assignment.tipe_assignment">
    </template>
    <a-divider orientation="left">
      <h3>Details</h3>
    </a-divider>
    <div class="content">
      <!-- <div v-if="!isEditableAssignment" class="mb-5"> -->
      <div v-if="isAttendanceSubmitted" class="mb-5">
        <a-alert
          message="Warning"
          description="You cannot edit the assignment, because your student has already answered this assignment"
          type="warning"
          show-icon
        />
      </div>
      <div v-else>
        <!-- <div> -->
        <ckeditor
          :editor="editor"
          id="edit-deskripsi-assignment"
          v-model="assignment.tugas_deskripsi"
          @input="onChange"
          :config="editorConfig"
          class="tugas_deskripsi"
        ></ckeditor>
      </div>
      <!-- <wysiwyg v-model="assignment.tugas_deskripsi" @change="onChange" style="height: 50vh;" /> -->
    </div>
    <div v-if="assignment.tipe_assignment === 'Multiple Choice'">
      <!-- <a-divider orientation="left">
        <h3>NEW COMPONENT - Questions</h3>
      </a-divider>
      <multiple-choice-editor
        :listSoal="assignment.questions"
        :isCbt="false"
        :useCard="false"
        @questions-change="questionChange"
      /> -->
      <div class="d-flex flex-column flex-md-row align-items-center">
        <div class="w-100 w-lg-75">
          <a-divider orientation="left">
          <!-- <h3>OLD COMPONENT - Questions</h3> -->
          <h3>Questions</h3>
        </a-divider>
        </div>
        <div class="w-auto d-flex flex-column flex-md-row ml-3">
          <a-button v-if="!isAttendanceSubmitted" @click.prevent="addQuestion" :loading="loadingAdNewQuestion" shape="round" type="primary" icon="plus"> Add Question</a-button>
        </div>
      </div>
      <div class="content">
        <div v-if="isAttendanceSubmitted" class="mb-5">
          <a-alert
            message="Warning"
            description="You cannot edit the assignment, because your student has already answered this assignment"
            type="warning"
            show-icon
          />
        </div>
        <div v-else>
          <div class="d-flex justify-content-between align-items-end">
            <div class="d-flex align-items-center mt-4">
              <a-button icon="arrow-left" @click.prevent="currentNumber--" :disabled="currentNumber == 1"></a-button>
              <div class="mx-2">{{currentNumber}} / {{assignment.questions.length}} question(s)</div>
              <a-button icon="arrow-right" @click.prevent="currentNumber++" :disabled="currentNumber == assignment.questions.length"></a-button>
            </div>
            <a-button
              v-if="assignment.questions.length > 1"
              @click.prevent="removeQuestion(currentNumber - 1)"
              :loading="loadingRemoveQuestion"
              type="danger"
              icon="close"
            >Remove Question</a-button>
          </div>
          <div
            v-for="(item, index) in assignment.questions"
            :key="index"
            class="my-4"
          >
            <a-row v-if="currentNumber === index + 1" :gutter="[16, 16]">
              <a-col :xs="12" :md="2">
                <a-input :value="index + 1" read-only disabled style="min-height: 48px"></a-input>
              </a-col>
              <a-col :xs="12" class="d-inline-block d-lg-none">
                <a-button
                  v-if="assignment.questions.length > 1"
                  @click.prevent="removeQuestion(index)"
                  :loading="loadingRemoveQuestion"
                  type="danger"
                  icon="close"
                  style="height: 48px"
                  class="w-100"
                ></a-button>
              </a-col>
              <a-col :xs="24" :lg="22">
                <a-row>
                  <a-col :span="24">
                    <ckeditor
                      v-model="item.soal"
                      :value="item.soal"
                      :editor="editor"
                      @input="onChange"
                      :config="editorConfig"
                    ></ckeditor>
                  </a-col>
                  <a-col :span="24">
                    <template v-for="number in institusi.jumlah_pilihan_ganda || 4">
                      <a-row :gutter="[32, 16]" class="mt-3" :key="number">
                        <a-col :xs="24" :xl="2">
                          <a-button
                            @click.prevent="submitAnswer(String.fromCharCode(65 + (number - 1)), 'EDIT', index)"
                            :type="item.jawaban ===  String.fromCharCode(65 + (number - 1))? 'primary' : 'default'"
                            style="width: 48px; height: 48px"
                            >{{String.fromCharCode(65 + (number - 1))}}</a-button
                          >
                        </a-col>
                        <a-col :xs="24" :xl="22">
                          <ckeditor
                            v-model="item[`pilihan_${String.fromCharCode(65 + (number - 1)).toLowerCase()}`]"
                            :editor="editor"
                            @input="onChange"
                            :config="editorConfig"
                          ></ckeditor>
                        </a-col>
                      </a-row>
                    </template>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </div>
          <!-- PREVIEW -->
          <!-- <div
            v-for="(item, index) in assignment.questions"
            :key="index"
            class="mb-4"
          >
            <a-row :gutter="[16, 16]">
              <a-col :xs="12" :lg="2">
                <a-input
                  :value="numberQuestion(index)"
                  read-only
                  disabled
                  style="height: 48px"
                />
              </a-col>
              <a-col :xs="12" class="d-inline-block d-lg-none">
                <a-button
                  @click.prevent="removeQuestion(index)"
                  :loading="loadingRemoveQuestion"
                  type="danger"
                  icon="close"
                  style="height: 48px"
                  class="w-100"
                ></a-button>
              </a-col>
              <a-col :xs="24" :lg="20">
                <ckeditor
                  v-model="item.soal"
                  :value="item.soal"
                  :editor="editor"
                  @input="onChange"
                  :config="editorConfig"
                ></ckeditor>
              </a-col>
              <a-col :xs="24" :lg="2" class="d-none d-lg-inline-block">
                <a-button
                  @click.prevent="removeQuestion(index)"
                  :loading="loadingRemoveQuestion"
                  type="danger"
                  icon="close"
                  style="width: 48px; height: 48px"
                ></a-button>
              </a-col>
            </a-row>
            <a-row :gutter="[32, 16]" class="mt-3">
              <a-col :xs="24" :xl="{ span: 2, offset: 2 }">
                <a-button
                  @click.prevent="submitAnswer('A', 'EDIT', index)"
                  :type="item.jawaban === 'A' ? 'primary' : 'default'"
                  style="width: 48px; height: 48px"
                  >A</a-button
                >
              </a-col>
              <a-col :xs="24" :xl="18">
                <ckeditor
                  :value="item.pilihan_a"
                  v-model="item.pilihan_a"
                  :editor="editor"
                  @input="onChange"
                  :config="editorConfig"
                ></ckeditor>
              </a-col>
              <a-col :xs="24" :xl="{ span: 2, offset: 2 }">
                <a-button
                  @click.prevent="submitAnswer('B', 'EDIT', index)"
                  :type="item.jawaban === 'B' ? 'primary' : 'default'"
                  style="width: 48px; height: 48px"
                  >B</a-button
                >
              </a-col>
              <a-col :xs="24" :xl="18">
                <ckeditor
                  :value="item.pilihan_b"
                  v-model="item.pilihan_b"
                  :editor="editor"
                  @input="onChange"
                  :config="editorConfig"
                ></ckeditor>
              </a-col>
              <a-col :xs="24" :xl="{ span: 2, offset: 2 }">
                <a-button
                  @click.prevent="submitAnswer('C', 'EDIT', index)"
                  :type="item.jawaban === 'C' ? 'primary' : 'default'"
                  style="width: 48px; height: 48px"
                  >C</a-button
                >
              </a-col>
              <a-col :xs="24" :xl="18">
                <ckeditor
                  :value="item.pilihan_c"
                  v-model="item.pilihan_c"
                  :editor="editor"
                  @input="onChange"
                  :config="editorConfig"
                ></ckeditor>
              </a-col>
              <a-col :xs="24" :xl="{ span: 2, offset: 2 }">
                <a-button
                  @click.prevent="submitAnswer('D', 'EDIT', index)"
                  :type="item.jawaban === 'D' ? 'primary' : 'default'"
                  style="width: 48px; height: 48px"
                  >D</a-button
                >
              </a-col>
              <a-col :xs="24" :xl="18">
                <ckeditor
                  :value="item.pilihan_d"
                  v-model="item.pilihan_d"
                  :editor="editor"
                  @input="onChange"
                  :config="editorConfig"
                ></ckeditor>
              </a-col>
            </a-row>
            <hr />
          </div> -->
          <!-- END PREVIEW -->
          <!-- CREATE QUESTION -->
          <!-- <a-row :gutter="[16, 16]">
            <a-col :xs="24" class="d-inline-block d-lg-none">
              <a-button
                @click.prevent="addQuestion"
                :loading="loadingAdNewQuestion"
                type="primary"
                icon="plus"
                style="width: 100%; height: 48px"
              ></a-button>
            </a-col>
            <a-col :xs="24" :lg="22">
              <ckeditor
                v-model="newQuestion.soal"
                :editor="editor"
                @input="onChange"
                :config="editorConfig"
              ></ckeditor>
            </a-col>
            <a-col :lg="2" class="d-none d-lg-inline-block">
              <a-button
                @click.prevent="addQuestion"
                :loading="loadingAdNewQuestion"
                type="primary"
                icon="plus"
                style="width: 48px; height: 48px"
              ></a-button>
            </a-col>
          </a-row>
          <a-row :gutter="[16, 16]" class="mt-3">
            <a-col :xs="24" :lg="{ span: 2, offset: 2 }">
              <a-button
                @click.prevent="submitAnswer('A')"
                :type="newQuestion.jawaban === 'A' ? 'primary' : 'default'"
                style="width: 48px; height: 48px"
                >A</a-button
              >
            </a-col>
            <a-col :xs="24" :lg="18">
              <ckeditor
                v-model="newQuestion.pilihan_a"
                :editor="editor"
                @input="onChange"
                :config="editorConfig"
              ></ckeditor>
            </a-col>
            <a-col :xs="24" :lg="{ span: 2, offset: 2 }">
              <a-button
                @click.prevent="submitAnswer('B')"
                :type="newQuestion.jawaban === 'B' ? 'primary' : 'default'"
                style="width: 48px; height: 48px"
                >B</a-button
              >
            </a-col>
            <a-col :xs="24" :lg="18">
              <ckeditor
                v-model="newQuestion.pilihan_b"
                :editor="editor"
                @input="onChange"
                :config="editorConfig"
              ></ckeditor>
            </a-col>
            <a-col :xs="24" :lg="{ span: 2, offset: 2 }">
              <a-button
                @click.prevent="submitAnswer('C')"
                :type="newQuestion.jawaban === 'C' ? 'primary' : 'default'"
                style="width: 48px; height: 48px"
                >C</a-button
              >
            </a-col>
            <a-col :xs="24" :lg="18">
              <ckeditor
                v-model="newQuestion.pilihan_c"
                :editor="editor"
                @input="onChange"
                :config="editorConfig"
              ></ckeditor>
            </a-col>
            <a-col :xs="24" :lg="{ span: 2, offset: 2 }">
              <a-button
                @click.prevent="submitAnswer('D')"
                :type="newQuestion.jawaban === 'D' ? 'primary' : 'default'"
                style="width: 48px; height: 48px"
                >D</a-button
              >
            </a-col>
            <a-col :xs="24" :lg="18">
              <ckeditor
                v-model="newQuestion.pilihan_d"
                :editor="editor"
                @input="onChange"
                :config="editorConfig"
              ></ckeditor>
            </a-col>
          </a-row> -->
          <!-- END CREATE QUESTION -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SwitchClass from './SwitchClass'
// import MultipleChoiceEditor from '@/components/app/CbtGuru/EditSoalCbt'
import moment from 'moment'
export default {
  name: 'EditAssignmentSession',
  components: {
    SwitchClass,
    // MultipleChoiceEditor,
  },
  data() {
    return {
      currentNumber: 1,
      assignment: {
        tipe_assignment: '',
        tugas_deadline2: '',
        tugas_deskripsi: '',
        questions: [
          {
            soal: null,
            jawaban: null,
            pilihan_a: null,
            pilihan_b: null,
            pilihan_c: null,
            pilihan_d: null,
            pilihan_e: null,
          },
        ],
        classList: [],
      },
      // questions: [],
      newQuestion: {
        jawaban: '',
      },
      loadingAdNewQuestion: false,
      loadingRemoveQuestion: false,
      meetingNumber: 0,
    }
  },
  props: ['session', 'isEdit', 'attendances', 'daftarKelas', 'sessionTime', 'isAttendanceSubmitted'],
  methods: {
    disabledStartDate(startValue) {
      let endValue = moment()
      const sesi = moment(`${this.session.tanggal_sesi}, ${this.sessionTime}`, 'YYYY-MM-DD, HH:mm')
      if (sesi.isAfter(endValue, 'second')) {
        endValue = moment(`${this.session.tanggal_sesi}, ${this.sessionTime}`, 'YYYY-MM-DD, HH:mm')
      }
      if (!startValue || !endValue) {
        return false
      }
      return startValue.isBefore(endValue, 'day')
    },
    changeChecked(payload) {
      if (payload.isAssigned) {
        this.assignment.classList = [...this.assignment.classList, { id_kelas: payload.kelas.id, id_sesi: payload.id_sesi }]
      } else {
        const classList = []
        // daftarSesi
        // console.log(this.assignment.classList)
        this.assignment.classList.forEach(kelas => {
          // console.log(payload.id_sesi, kelas.id_sesi)
          if (Number(this.$route.params.id) === kelas.id_sesi) {
            classList.push({ id_kelas: kelas.id_kelas, id_sesi: kelas.id_sesi })
          } else {
            if (payload.id_sesi !== kelas.id_sesi) {
              classList.push(kelas)
            }
          }
        })

        this.assignment.classList = classList
      }
      this.$emit('updatedAssignment', this.assignment)
    },
    questionChange(payload) {
      // console.log('PARENT', payload)
    },
    onChange() {
      if (this.assignment.tipe_assignment === '') {
        this.assignment.tugas_deadline2 = ''
      }
      this.$emit('updatedAssignment', this.assignment)
    },
    numberQuestion(index) {
      return ++index
    },
    submitAnswer(value, type = 'NEW', index = 0) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to choose this choice to be answer ?</div>
        ),
        onOk: () => {
          if (value) {
            if (type === 'NEW') {
              this.newQuestion.jawaban = value
            } else {
              this.assignment.questions[index].jawaban = value
            }
            this.$notification.success({
              message: 'Success',
              description:
                'This Question already has an answer',
            })
          } else {
            this.$notification.error({
              message: 'Error',
              description:
                'Choosing answer failed, Make sure you choose an answer',
            })
          }
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Choose',
      })
    },
    addQuestion() {
      this.loadingAdNewQuestion = true
      const isValidated = this.assignment.questions.find(q => {
        if (!q.soal || !q.jawaban) return true
        const multipleChoiceAmount = this.institusi.jumlah_pilihan_ganda || 4
        for (let i = 0; i < multipleChoiceAmount; i++) {
          const huruf = String.fromCharCode(65 + i).toLowerCase()
          if (!q[`pilihan_${huruf}`]) return true
        }
        return false
      })
      if (isValidated) {
        this.loadingAdNewQuestion = false
        return this.$notification.error({
          message: 'Error',
          description:
            'Adding question failed, Make sure you have question and answer',
        })
      }
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to add new question ?</div>
        ),
        onOk: () => {
          // this.assignment.questions.push(this.newQuestion)
          this.loadingAdNewQuestion = false
          this.assignment.questions.push({
            soal: null,
            jawaban: null,
            pilihan_a: null,
            pilihan_b: null,
            pilihan_c: null,
            pilihan_d: null,
            pilihan_e: null,
          })
          this.currentNumber = this.assignment.questions.length
          // this.noQuestion++
          // this.newQuestion = { jawaban: '' }
          this.$emit('updatedAssignment', this.assignment)
          this.$notification.success({
            message: 'Success',
            description:
              'New question is added',
          })
        },
        onCancel: () => {
          this.loadingAdNewQuestion = false
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Add',
      })
    },
    removeQuestion(index) {
      // console.log(index)
      this.loadingRemoveQuestion = true
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to remove this question ?</div>
        ),
        onOk: () => {
          this.loadingRemoveQuestion = false
          this.assignment.questions = this.assignment.questions.filter((q, i) => i !== index)
          this.$emit('updatedAssignment', this.assignment)
          this.currentNumber = 1
          // this.noQuestion--
          this.$notification.success({
            message: 'Success',
            description:
              'This Question has been removed',
          })
        },
        onCancel: () => {
          this.loadingRemoveQuestion = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Remove',
      })
    },
  },
  watch: {
    daftarKelas(newVal, oldVal) {
      if (newVal.length) {
        newVal.forEach(kelas => {
          if (kelas.id === this.currentSlot.slot.kela.id) {
            kelas.slots.forEach((slot, i) => {
              if (slot.sesis[0].id === Number(this.$route.params.id)) {
                this.meetingNumber = i
                this.assignment.classList.push({
                  id_kelas: kelas.id,
                  id_sesi: slot.sesis[0].id,
                })
              }
            })
          }
        })
      }
    },
    isEdit(newVal) {
      this.assignment.tugas_deadline2 = this.session.tugas_deadline2 ? moment(this.session.tugas_deadline2).locale('id') : ''
      this.assignment.tipe_assignment = this.session.tipe_assignment ? this.session.tipe_assignment : ''
      this.assignment.tugas_deskripsi = this.session.tugas_deskripsi || ''
      // console.log(this.assignment.questions)
      this.assignment.questions = this.session.pg_soals?.length ? this.session.pg_soals : [...this.assignment.questions]
      // console.log(this.assignment.questions)
      this.$emit('updatedAssignment', this.assignment)
    },
  },
  computed: {
    defaultTime() {
      return moment ? moment('00:00:00', 'HH:mm:ss') : ''
    },
    currentSlot() {
      return this.$store.state.slot.currentSlot
    },
    configDate() {
      return { rules: [{ required: this.assignment.tipe_assignment !== '' || this.assignment.tipe_assignment }] }
    },
    tipe_assignment() {
      return this.assignment.tipe_assignment
    },
    editor() {
      return this.$store.state.ckEditor.editor
    },
    editorConfig() {
      return this.$store.state.ckEditor.editorConfig
    },
    institusi() {
      return this.$store.state.master.institusi
    },
    // noQuestion() {
    //   return this.assignment.questions.length ? this.assignment.questions.length + 1 : 1
    // },
  },
  created() {
    this.assignment.tugas_deadline2 = this.session.tugas_deadline2 ? moment(this.session.tugas_deadline2).locale('id') : ''
    this.assignment.tipe_assignment = this.session.tipe_assignment ? this.session.tipe_assignment : ''
    this.assignment.tugas_deskripsi = this.session.tugas_deskripsi || ''
    this.assignment.questions = this.session.pg_soals?.length ? this.session.pg_soals : [...this.assignment.questions]
    // console.log(this.session, this.daftarKelas)
    // console.log('ASSIGNMENT DATA', this.assignment)
  },
  // watch: {
  //   isEdit(newVal, oldVal) {
  //     if (!newVal) {
  //       this.assignment.tugas_deadline2 = this.session.tugas_deadline2 ? moment(this.session.tugas_deadline2).locale('id') : ''
  //       this.assignment.tipe_assignment = this.session.tipe_assignment ? this.session.tipe_assignment : ''
  //       this.assignment.tugas_deskripsi = this.session.tugas_deskripsi
  //     }
  //   },
  //   tipe_assignment(newVal) {
  //     if (newVal === '') {
  //       this.assignment.tugas_deadline2 = ''
  //     }
  //   },
  // },
}
</script>
<style lang="scss">
.assignmentStudent {
  .content {
    font-family: "Mukta", "sans-serif";
    font-weight: 500;
    font-size: 16px;
    margin: auto;
    width: 65%;
    @media (max-width: 769px) {
      width: 100%;
    }
    .ck-editor__editable {
      min-height: 15vh;
    }
  }
}
</style>
